import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import Seo from "src/components/SEO"
import { PrimaryTitle } from "src/components/Typography"
import { get } from "lodash"
import getSlug from "src/parsers/slug-parser"
import {
  PageHeader,
  Container,
  ArticleList,
  Article,
  ArticleInner,
  ArticleImageWrapper,
  ArticleImage,
  ArticleImageSharp,
  ArticleContent,
  ArticleTitle,
  ArticleMeta,
  ArticleAuthor,
  ArticleAuthorPhotoWrapper,
  ArticleAuthorPhoto,
  ArticleAuthorPhotoSharp,
  ArticleNoAuthorIcon,
  ArticleAuthorName,
  ArticleDate,
} from "src/pages/blog"

/* eslint-disable import/no-anonymous-default-export, no-anonymous-exports-page-templates */
export default (props) => {
  const { name } = props.pageContext
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allStrapiBlogPosts(
          filter: { publish: { eq: true } }
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            title
            slug
            date(formatString: "MMMM D, YYYY")
            author {
              Name
              picture {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(height: 30, quality: 95, layout: FIXED)
                  }
                }
              }
            }
            cover_image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 289
                    height: 192
                    quality: 80
                    layout: FIXED
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  const blog = data.allStrapiBlogPosts.nodes
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .filter((post) => post.author && post.author.Name === name)

  const [observed, setObserved] = useState(false)
  const articleRefs = []

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <>
      <Seo
        title={name}
        description="Follow our blog to stay informed about our work and successes, animal and industry news, and the goals of Anima International. Get to know us better!"
      />
      <PageHeader className={observed ? "-observed" : ""}>
        <PrimaryTitle as="h1">
          <small>Author:</small> {name}
        </PrimaryTitle>
      </PageHeader>
      {blog.length > 0 && <Container>
        <ArticleList>
          {blog.map(({ title, slug, author, date, cover_image }, index) => {
            const [articleObserved, setArticleObserved] = useState(false)

            const articleRef = {
              current: (articleRef) => (articleRefs[index] = articleRef),
            }

            useIntersectionObserver({
              callback: (change) => {
                if (change.isIntersecting) {
                  setArticleObserved(true)
                }
              },
              elementRef: articleRef,
            })

            return (
              <Article
                ref={articleRef}
                className={articleObserved ? "-observed" : ""}
                key={index}
              >
                <ArticleInner to={`/blog/${getSlug({ slug, title })}`}>
                  {cover_image && (
                    <ArticleImageWrapper>
                      {get(
                        cover_image,
                        "localFile.childImageSharp.gatsbyImageData"
                      ) ? (
                        <ArticleImageSharp
                          image={get(
                            cover_image,
                            "localFile.childImageSharp.gatsbyImageData"
                          )}
                          alt=""
                        />
                      ) : (
                        <ArticleImage
                          src={cover_image.localFile.publicURL}
                          loading="lazy"
                        />
                      )}
                    </ArticleImageWrapper>
                  )}
                  <ArticleContent>
                    <ArticleTitle>{title}</ArticleTitle>
                    <ArticleMeta>
                      {author && (
                        <ArticleAuthor>
                          <ArticleAuthorPhotoWrapper>
                            {author.picture ? (
                              author.picture.localFile.childImageSharp ? (
                                <ArticleAuthorPhotoSharp
                                  image={get(
                                    author,
                                    "picture.localFile.childImageSharp.gatsbyImageData"
                                  )}
                                  alt={author.Name}
                                />
                              ) : (
                                <ArticleAuthorPhoto
                                  src={author.picture.localFile.publicURL}
                                  alt={author.Name}
                                />
                              )
                            ) : (
                              <ArticleNoAuthorIcon name="ok-bird" />
                            )}
                          </ArticleAuthorPhotoWrapper>
                          <ArticleAuthorName>{author.Name}</ArticleAuthorName>
                        </ArticleAuthor>
                      )}
                      <ArticleDate>{date}</ArticleDate>
                    </ArticleMeta>
                  </ArticleContent>
                </ArticleInner>
              </Article>
            )
          })}
        </ArticleList>
      </Container>}
    </>
  )
}
